.loading-box {
    position:fixed;
	height:100%;
	width:100%;
	display:flex;
	flex-direction:column;
	justify-content:center;
	z-index:9999;
    align-items: center;
	background:#000;
    transition: all 0.3s ease;
}
.logo-box {
	animation: LoadingAnimation 400ms alternate  ease-in-out forwards;
	width:30rem;
	height:30rem;
}
.logo-loading  {
	fill:#fff;
}
@keyframes LoadingAnimation {
	0% {
		transform: scale(0.5);
		opacity:0.2;
	}
	100% {
		transform: scale(1);
		opacity:1;
	}
}

@media screen and (max-width: 900px) {
	.logo-box {
		width:20rem;
		height:20rem;
	}
}