.wrapper {
    padding: 20px;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.wrapper {
    padding: 20px;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
}
:root {
  --primary: #1888ff;
}

.btn {
  padding: 8px 20px;
  margin-top: -20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: #1888ff;
  background-color: var(--primary);
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid #1888ff;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}

.navbar {
    transition: all 0.3s ease;	
	background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	z-index:100;
	-webkit-transform: translateY(-8%);
	        transform: translateY(-8%);
}
.navbar-main-page{
    transition: all 0.3s ease;	
    background: rgba(0, 0, 0, 0.3);	
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.navbar-logo {
	color: #fff;
	justify-self: start;
	margin-left: 20px;
	margin-top: -30px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.navbar-logo:hover {
	color: #fff;
	border-radius: 0;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  margin-top: -5px;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  color: black;
  background-color: #f8383f;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  
  .fa-caret-down {
    display: none;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
	-webkit-transform: translateY(-1%);
	        transform: translateY(-1%);
	justify-content: center;
    padding-bottom: 8rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    margin-right: 2rem;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #747272;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(10%, 40%);
            transform: translate(10%, 40%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .close-navbar-icon {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

}

.dropdown-market {
    transition: all 0.5s ease-in-out forwards;
  width: 200px;
  margin-left: -170px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
}

.dropdown-market li {
	background: #c83838;
	cursor: pointer;
    transition: all 0.3s ease;
	-webkit-animation: dropdownAnimation 200ms ease-in-out forwards;
	        animation: dropdownAnimation 200ms ease-in-out forwards;
}

.dropdown-market li:hover {
  background: #e85656;
    transition: all 0.3s ease-in-out forwards;
}

.dropdown-market.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
    transition: all 0.5s ease;
}
.dropdown-link:hover {
  color: #fff;
    transition: all 0.5s ease;
}
@-webkit-keyframes dropdownAnimation {
    0% {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
		-webkit-transform: translateY(-100%) scale(0);
		        transform: translateY(-100%) scale(0);
		opacity:0
    }
    100% {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
		-webkit-transform:  translateY(0%) scale(1);
		        transform:  translateY(0%) scale(1);
		opacity:1
    }
}
@keyframes dropdownAnimation {
    0% {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
		-webkit-transform: translateY(-100%) scale(0);
		        transform: translateY(-100%) scale(0);
		opacity:0
    }
    100% {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
		-webkit-transform:  translateY(0%) scale(1);
		        transform:  translateY(0%) scale(1);
		opacity:1
    }
}

@media screen and (max-width: 1200px) {
	.dropdown-market {
		display: none;
	}
}
.backtotop {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  transition: all 0.3s ease-out;
  z-index: 10000;
}
.backtotop .icon {
  width: 100%;
  height: 100%;
}

.backtotop.hidden {
  width: 0;
  height: 0;
}

.backtotop:hover, .backtotop:focus {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

@media screen and (max-width: 1010px) {
  .backtotop {
    bottom: 0.5rem;
    right: 0.5rem;
  }
  .backtotop:hover, .backtotop:focus {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.loading-box {
    position:fixed;
	height:100%;
	width:100%;
	display:flex;
	flex-direction:column;
	justify-content:center;
	z-index:9999;
    align-items: center;
	background:#000;
    transition: all 0.3s ease;
}
.logo-box {
	-webkit-animation: LoadingAnimation 400ms alternate  ease-in-out forwards;
	        animation: LoadingAnimation 400ms alternate  ease-in-out forwards;
	width:30rem;
	height:30rem;
}
.logo-loading  {
	fill:#fff;
}
@-webkit-keyframes LoadingAnimation {
	0% {
		-webkit-transform: scale(0.5);
		        transform: scale(0.5);
		opacity:0.2;
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity:1;
	}
}
@keyframes LoadingAnimation {
	0% {
		-webkit-transform: scale(0.5);
		        transform: scale(0.5);
		opacity:0.2;
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity:1;
	}
}

@media screen and (max-width: 900px) {
	.logo-box {
		width:20rem;
		height:20rem;
	}
}
