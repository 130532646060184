.dropdown-market {
    transition: all 0.5s ease-in-out forwards;
  width: 200px;
  margin-left: -170px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
}

.dropdown-market li {
	background: #c83838;
	cursor: pointer;
    transition: all 0.3s ease;
	animation: dropdownAnimation 200ms ease-in-out forwards;
}

.dropdown-market li:hover {
  background: #e85656;
    transition: all 0.3s ease-in-out forwards;
}

.dropdown-market.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
    transition: all 0.5s ease;
}
.dropdown-link:hover {
  color: #fff;
    transition: all 0.5s ease;
}
@keyframes dropdownAnimation {
    0% {
        transform: translateY(-100%);
		transform: translateY(-100%) scale(0);
		opacity:0
    }
    100% {
        transform: translateY(0%);
		transform:  translateY(0%) scale(1);
		opacity:1
    }
}

@media screen and (max-width: 1200px) {
	.dropdown-market {
		display: none;
	}
}