.wrapper {
    padding: 20px;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
}