.backtotop {
	position:fixed;
	bottom:2rem;
	right:2rem;
	width:3rem;
	height:3rem;
	transition: all 0.3s ease-out;
	z-index:10000;
	
	.icon{	
		width:100%;
		height:100%;
	}
}
.backtotop.hidden{
	width:0;
	height:0;
}

.backtotop:hover,.backtotop:focus {
	transform:scale(1.4);
}

@media screen and (max-width: 1010px) {
	.backtotop {
		bottom:0.5rem;
		right:0.5rem;
	}
	.backtotop:hover,.backtotop:focus {
		transform:scale(1);
	}
}